import type { HeadFC } from 'gatsby';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { PageContext } from 'gatsby-plugin-react-i18next/dist/types';
import * as React from 'react';
import Container from '../components/Container';
import CtaNewProject from '../components/CtaNewProject';
import HeroInnerPage from '../components/HeroInnerPage';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Seo from '../components/Seo';
import ServicesList from '../components/ServicesList';
import SectionTitle from '../components/SectionTitle';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ServicesPage = ({ data }: any) => {
  const { t } = useTranslation();

  const { services: servicesMarkdown, usecases: useCasesMarkdown } = data;
  const { nodes } = servicesMarkdown;
  const { frontmatter } = nodes[0] || {};
  const { services } = frontmatter || [];

  const { nodes: useCasesNodes } = useCasesMarkdown;

  return (
    <Layout>
      <HeroInnerPage title={t('page.services.title')} text={t('page.services.text')} />
      <Section>
        <Container>
          {/* <div className="grid grid-cols-1 gap-8 lg:grid-cols-6">
            <div className="lg:col-span-4">
              <ServicesList services={services} />
            </div>
            <div className="lg:col-span-2">
              <div className="prose sticky top-[8rem]  bg-emerald-100 p-12">
                <p>{t('page.services.aside.text_1')}</p>
                <p>{t('page.services.aside.text_2')}</p>
              </div>
            </div>
          </div> */}
          <div className="mx-auto max-w-3xl">
            <ServicesList services={services} />
          </div>
        </Container>
      </Section>
      <Section className="bg-slate-50">
        <Container>
          <div className="prose">
            <SectionTitle center>{t('page.use_cases.title')}</SectionTitle>
            <p
              className="text-center"
              dangerouslySetInnerHTML={{ __html: t('page.use_cases.text') }}
            />
          </div>

          <div className="prose mx-auto mt-12 flex max-w-screen-md flex-col gap-y-8">
            {useCasesNodes.map((node: any, i: any) => {
              const { frontmatter, html } = node;
              const { title, category, problem } = frontmatter;
              const imageData = frontmatter.image || null;
              const image = getImage(imageData) || null;
              return (
                <div key={i}>
                  <div
                    className="grid scroll-m-32 grid-cols-1 items-center md:grid-cols-2"
                    id={category}
                  >
                    <div className="prose h-full bg-emerald-500 p-8 prose-h2:mt-4 prose-h2:text-xl md:p-12 lg:py-20">
                      <p className="m-0">
                        <span className="rounded-full bg-blue px-3 py-1 text-sm font-semibold uppercase tracking-wider text-white">
                          {category}
                        </span>
                      </p>
                      <h2>{title}</h2>
                    </div>
                    <div className="h-full flex-shrink-0">
                      {image && (
                        <GatsbyImage
                          image={image}
                          alt=""
                          className="h-full w-full"
                          objectFit="cover"
                        />
                      )}
                    </div>
                  </div>
                  <div className="prose mt-8 prose-h3:text-lg  md:px-12 lg:mt-12">
                    <h3>
                      {t('common.probem')} {problem}
                    </h3>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                </div>
              );
            })}
          </div>
        </Container>
      </Section>
      <CtaNewProject />
    </Layout>
  );
};

export default ServicesPage;

export const Head: HeadFC = ({ location, pageContext }) => {
  const { language } = pageContext as PageContext;

  type Title = {
    [key: string]: string;
  };

  const title = {
    fr: 'Nos services',
    en: 'Our services',
  } as Title;

  return <Seo location={location} title={`${title[language]} | Austra`} />;
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    services: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/services/i" }
        frontmatter: { locale: { eq: $language } }
      }
    ) {
      nodes {
        frontmatter {
          services {
            list
            title
            image {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  height: 128
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
          locale
        }
      }
    }

    usecases: allMarkdownRemark(
      filter: {
        frontmatter: { locale: { eq: $language } }
        fileAbsolutePath: { regex: "/use-cases/i" }
      }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        frontmatter {
          title
          category
          problem
          image {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                height: 220
                layout: CONSTRAINED
                placeholder: NONE
              )
            }
          }
        }
        id
        html
        fields {
          slug
        }
      }
    }
  }
`;
