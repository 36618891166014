import { GatsbyImage, getImage, IImage, ImageDataLike, StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import ListCheckItem from './ListCheckItem';

type Service = {
  title?: string;
  image?: ImageDataLike | null;
  list?: string[];
};

type Props = {
  services: Service[];
};

const ServicesList = ({ services }: Props) => {
  const { t } = useTranslation();
  if (!services)
    return (
      <>
        <div className="prose">
          <h1>{t('common.message.onlyinrench')}</h1>
          <p>
            <Button to="/services" language="fr">
              {t('common.button.back')}
            </Button>
          </p>
        </div>
      </>
    );

  return (
    <div className="prose prose-h2:m-0 prose-h2:text-xl">
      {services.map((service: Service, i) => {
        const imageData = service.image || null;
        const image = getImage(imageData) || null;
        return (
          <div key={i}>
            <div className="flex flex-col items-center gap-4 sm:flex-row sm:items-start sm:gap-12">
              <div className="flex-shrink-0 sm:-mt-4">
                {image && <GatsbyImage image={image} alt="" objectFit="contain" />}
              </div>
              <div>
                <h2 className="mx-auto text-center sm:max-w-md sm:text-left">{service.title}</h2>
                <div
                  className={`grid grid-cols-1 gap-x-16  ${
                    service.list && service.list.length > 1 ? 'md:grid-cols-2' : ''
                  }`}
                >
                  {service.list &&
                    service.list.map((item, j) => (
                      <div className="not-prose mt-8" key={j}>
                        <ul>
                          <ListCheckItem>{item}</ListCheckItem>
                        </ul>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {i < services.length - 1 && <hr />}
          </div>
        );
      })}
    </div>
  );
};

export default ServicesList;
